import React from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CImage,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import './AppLogin.css'
import { useAuth0 } from '@auth0/auth0-react';



const AppLogin = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently
  } = useAuth0();
    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol xs={12} className="d-block d-sm-none">
              <CCard className="text-white bg-dark-gradient py-5 ">
                    <CCardBody className="text-center">
                    <CImage src="/remac-logo-white.png" height={50} />
                      <div style={{ paddingTop: '5px' }}>
                        Welcome to your management HUB for all <br/> REMAC Apps
                      </div>
                    </CCardBody>
                  </CCard>
              </CCol>
              <CCol md={8}>
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <CForm>
                        <h1>Login</h1>
                        <p className="text-medium-emphasis">Sign In to your account</p>
                        
                        <CRow>
                          <CCol xs={6}>
                            <CButton color="primary" className="px-4" onClick={() => loginWithRedirect()}>
                              Login
                            </CButton>
                          </CCol>
                          <CCol xs={6} className="text-right">
                            
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                  <CCard className="text-white bg-dark-gradient py-5 d-none d-lg-block" style={{ width: '44%' }}>
                    <CCardBody className="text-center">
                    <CImage src="/remac-logo-white.png" height={50} />
                      <div style={{ paddingTop: '5px' }}>
                        Welcome to your management HUB for all <br/> REMAC Apps
                      </div>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      )
}

export default AppLogin