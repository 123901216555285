/* eslint-disable react/prop-types */
import { CToast, CToastBody, CToastHeader } from '@coreui/react-pro';
import React, { createContext, useState } from 'react';

const initialState = {
    title: '',
    body: '',
  };

  const NotificationContext = createContext({
    ...initialState,
    setAlert: () => {},
  });

  export const NotificationProvider =({ children }) => {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [toast, setToast] = useState(0)
    
    const setAlert = (title, body) => {
        //console.log(`settintAlert title: ${title} body: ${body}`)
        if(title && body) {
        const t = (
            <CToast>
                <CToastHeader closeButton>
                <svg
                    className="rounded me-2"
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid slice"
                    focusable="false"
                    role="img"
                >
                    <rect width="100%" height="100%" fill="#007aff"></rect>
                </svg>
                <strong className="me-auto">{title}</strong>
                <small>now</small>
                </CToastHeader>
                <CToastBody>{body}</CToastBody>
            </CToast>
        )
        setToast(t)
        }
    }

    return (
        <NotificationContext.Provider 
        value={{
            title,
            body,
            toast,
            setAlert
        }}>{children}</NotificationContext.Provider>
    )
  }

  export default NotificationContext