import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyC5J5r0_xUj3Upo7M27NylAmwJWSWzm6SQ",
    authDomain: "office-notifications-40af5.firebaseapp.com",
    projectId: "office-notifications-40af5",
    storageBucket: "office-notifications-40af5.appspot.com",
    messagingSenderId: "270240286287",
    appId: "1:270240286287:web:1c8b5b434106153db40c3a",
    measurementId: "G-3YPXKHRN2F"
  };
  
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
    console.log('Getting new token..')
    return getToken(messaging, {vapidKey: 'BChH8Azio80ePFGny5KQQAILQpAwKL09zU4BCOsYQZhMtcSagUTV8EhsMdJlUGGxfzMo367jDbNQB3Ac6pVGeS0'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }
  
  export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });